import { IStoreUser } from "@/api/users.ts";
import { API_URL } from "@/config";
import axios from "axios";
const API = `${API_URL}/user`;

export interface Tokens {
    access_token: string;
    refresh_token: string;
}

export interface ICreateUser {
    first_name: string;
    last_name: string;
    email: string;
    is_admin: boolean;
    is_driver: boolean;
}

export const forgotPassword = async (email: string) => {
    const result = await axios.post(`${API}/password-recovery/${email}`);
    return result.data;
};

export const resetPassword = async (data: {
    token: string;
    new_password: string;
}) => {
    const result = await axios.post(`${API}/reset-password`, data);
    return result.data;
};

export const createUser = async (data: ICreateUser) => {
    const result = await axios.post<IStoreUser>(`${API}/create`, data);
    return result.data;
};

export const login = async (email: string, password: string) => {
    const result: { data: { access_token: string; token_type: string } } =
        await axios.post(`${API}/login`, {
            email,
            password,
        });
    return result.data;
};

export const changePassword = async (
    current_password: string,
    new_password: string
) => {
    try {
        const result = await axios.put(`${API}/change-password`, {
            current_password,
            new_password,
        });
        return result.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

// export const refreshTokenRequest = async (refreshToken: string) => {
//   const result = await axios.post(`${API}/refresh`, {
//     refresh_token: refreshToken,
//   });
//   return result.data;
// };
